<template>
  <div class="article">
    <RenderlessPagesList :byTags="byTags" :byCategories="byCategories" :notTags="notTags" :notCategories="notCategories">
      <div slot-scope="{ posts, formatDate }">
          <div v-for="post in posts" v-bind:key="index" v-if="`/blog/${ post.path }` == articlepath" class="post">
            <div style="text-align:center;">
            <h1 class="blog__title">{{ post.title }}</h1>
            <h3 class="post__description" style="color:#525a61; font-variant: small-caps">{{ post.description }}</h3>
            <router-link to="/blog">Back</router-link>
            <br>
            </div>
            <div class="post-content text-left" style="font-size:1.2em;" v-html="post.__content"></div>
            <div class="text-left" style="font-size:1.2em; color:#525a61;" v-if="post.author2">
              &nbsp;&nbsp;&nbsp;&nbsp;Written by: {{ post.author1.first_name }} {{ post.author1.last_name }},
               {{ post.author2.first_name }} {{ post.author2.last_name }}
            </div>
            <div class="text-left" style="font-size:1.2em; color:#525a61;" v-else>
              &nbsp;&nbsp;&nbsp;&nbsp;Written by: {{ post.author.first_name }} {{ post.author.last_name }}
            </div>
            <div class="post-date text-left" style="font-size:1.2em; color:#525a61;">&nbsp;&nbsp;&nbsp;&nbsp;Posted: {{ formatDate(post.created) | dateParse('MM/DD/YYYY') | dateFormat('D MMMM, YYYY') }}</div>
            <div class="text-left" style="font-size:1.2em; color:#525a61;">
            &nbsp;&nbsp;&nbsp;&nbsp;Tags:
              <span v-for="(tag, index) in post.tags" v-bind:key="tag">
                <span v-if="index != 0">, </span>
                <span>{{tag}}</span>
              </span>
            </div>
          <br>
          <br>
        </div>
      </div>
    </RenderlessPagesList>
  </div>
</template>

<script>
import RenderlessPagesList from "../components/RenderlessPagesList"
import blogPageListProps from "../components/blogPageListProps"

export default {
  name: 'article',
  props: {
    blogPageListProps,
  },
  components: {
    RenderlessPagesList
  },
  data() {
    return {
      // article: require(`../posts/${this.id}.md`), // eslint-disable-line global-require, import/no-dynamic-require
      articlepath: this.$route.path,
    };
    // console.log(articlepath)
  },
};
</script>

<style>
.article{
  /* justify-content: center; */
  hyphens: auto;
}
</style>
